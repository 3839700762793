<template>
  <div class="form-sdk">
    <!-- <v-dialog
      v-model="actionModal"
      scrollable
      color="#F9FAFA"
      persistent
      width="100%"
      max-height="50vh"
      max-width="750px"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-transition"
    >

    </v-dialog> -->

    <div class="modal-container" v-if="isModal">
      <div class="modal-view" v-click-outside="outside">
        <div class="modal-view__header">
          <div class="modal-view__header__title">
            <h3></h3>
          </div>
          <div class="modal-view__header__close">
            <v-btn color="#FF0000" @click="closeSdk" icon
              ><v-icon size="20">mdi-close</v-icon></v-btn
            >
          </div>
        </div>
        <div class="modal-view__content">
          <FormRender
            :isEmbedded="true"
            @pinSDkForm="pinDialog = $event"
            @openSDKForm="actionModal = true"
            @endSDK="endSDK"
            @formNotFound="formNotFound"
            @closeSDKForm="actionModal = false"
            :id="id"
          />
        </div>
        <div class="modal-view__footer">
          <a
            href="https://tryhyphen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> powered by </span>
            <img src="@/assets/newLogo.png" alt="powered by hyphen" />
          </a>
        </div>
      </div>
    </div>
    <v-navigation-drawer
      v-else
      :width="$vuetify.breakpoint.xs ? '100%' : '450px'"
      v-model="actionModal"
      right
      temporary
      stateless
      v-click-outside="outside"
      app
    >
      <div class="form-render">
        <div style="text-align: right">
          <v-btn
            v-if="$vuetify.breakpoint.xs"
            color="#FF0000"
            @click="closeSdk"
            icon
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </div>
        <div class="form-render__wrapper">
          <FormRender
            :isEmbedded="true"
            @pinSDkForm="pinDialog = $event"
            @openSDKForm="actionModal = true"
            @endSDK="endSDK"
            @formNotFound="formNotFound"
            @closeSDKForm="actionModal = false"
            :id="id"
          />
        </div>

        <div class="powered-by-hyphen">
          <a
            href="https://tryhyphen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> powered by </span>
            <img src="@/assets/newLogo.png" alt="powered by hyphen" />
          </a>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import FormRender from "../components/FormRender.vue";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionModal: true,
      pinDialog: false,
      isModal: true,
    };
  },
  components: { FormRender },
  mounted() {
    // select htmlm, body, element and apply css overfloe: hidden
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
  },
  methods: {
    closeSdk() {
      window.parent.postMessage({ closeSDK: true }, "*");
    },
    endSDK() {
      this.closeSdk();
    },

    formNotFound() {
      setTimeout(() => {
        this.closeSdk();
      }, 5000);
    },

    outside(e) {
      //get click position
      var x = e.clientX;
      var y = e.clientY;
      //get element size and position in the page
      var element = document.querySelector(".v-navigation-drawer");
      var rect = element.getBoundingClientRect();
      // check if the click is in the element
      if (x > rect.left && x < rect.right && y > rect.top && y < rect.bottom) {
        // console.log("inside");
      } else {
        if (!this.pinDialog) {
          this.closeSdk();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.theme--light,
body,
html,
.theme--light.v-application {
  background-color: transparent;
}

.form-sdk {
  height: 100vh;
  background: transparent;
  width: 100vw;
  z-index: 9999px;
  overflow: hidden;
}

.modal-container {
  height: 100vh;
  width: 100vw;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  // blur background
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);

  .modal-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    max-height: 100vh;
    height: auto;
    background-color: #fff;

    // desktop
    @media (min-width: 768px) {
      max-height: 75vh;
      border-radius: 10px;
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      width: 100%;
      background-color: #fff;

      // desktop
      @media (min-width: 768px) {
        border-radius: 10px 10px 0 0;
      }
    }

    &__content {
      height: auto;
      overflow: auto;
      width: 100%;

      @media (min-width: 768px) {
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--v-primary-base);
          border-radius: 10px;
        }
      }
    }

    &__footer {
      padding: 20px 45px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;

      //desktop
      @media (min-width: 768px) {
        border-radius: 0 0 10px 10px;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        gap: 5px;

        span {
          font-size: 13px;
          color: #8f96a1;
          text-decoration: none;
        }

        img {
          height: 14px;
          margin-left: 5px;
          opacity: 0.8;
        }
      }
    }
  }
}

.form-render {
  width: 100%;
  z-index: 9999px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  &__wrapper {
    flex: 1;
    overflow: auto;
    padding: 10px;

    @media (min-width: 768px) {
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
      }
    }
  }

  .powered-by-hyphen {
    display: block;
    margin: 15px 25px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      @media (max-width: 500px) {
        display: flex;
      }
    }

    span {
      font-size: 14px;
      color: #8f96a1;
      margin-right: 5px;
      text-decoration: none;
    }

    img {
      height: 15px;
      margin-left: 5px;
      opacity: 0.8;
    }
  }

  a {
    display: block;
    margin: auto 40px;
  }
}
</style>
